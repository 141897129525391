<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3>Workspaces</h3>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="page = 1; searchWorkspace()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="workspaceName" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Package Plan</th>
                    <th>PIC Name</th>
                    <th>Payment Method</th>
                    <th>Created At</th>
                    <th>Marketing Conv</th>
                    <th>Utility Conv</th>
                    <th>Authentication Conv</th>
                    <th>Service Conv</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(workspace, i) in workspaceList" :key="i">
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      {{ workspace.name }}
                      <Status :online="workspace.status == 'active'" />
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <el-tag effect="dark" size="mini" :type="tagColor[workspace.products[0].name]">
                        {{ workspace.products ? workspace.products[0].name : '-' }}
                      </el-tag>
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">{{ workspace.pic && workspace.pic.full_name ? workspace.pic.full_name : '-' }}</td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <span class="ml-2">{{ workspace.products[0].is_postpaid ? 'Post Paid' : 'Pre Paid' }}</span>
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">{{ moment(workspace.created_at).format('DD MMM YYYY, HH:mm') }}</td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.marketing_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.marketing_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.utility_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.utility_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.authentication_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.authentication_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td @click="setWorkspace(workspace)" class="cursor-pointer">
                      <span v-if="workspace.products[0].pricing && workspace.products[0].pricing.whatsapp && workspace.products[0].pricing.whatsapp.service_conversation">
                        Rp.{{ workspace.products[0].pricing.whatsapp.service_conversation }}
                      </span>
                      <span v-else>Not Set</span>
                    </td>
                    <td class="text-capitalize">
                      <el-dropdown trigger="hover" @command="commandHandler($event, workspace)">
                        <el-button type="primary" size="small">
                          Actions<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="copyWorkspaceId">
                            Copy Workspace ID
                          </el-dropdown-item>
                          <el-dropdown-item command="setWorkspace" v-if="checkPermission.read">
                            Manage Workspace
                          </el-dropdown-item>
                          <el-dropdown-item command="editWorkspace" v-if="checkPermission.update && ['idle','active'].includes(workspace.status)">
                            Edit
                          </el-dropdown-item>
                          <el-dropdown-item command="handlerApprovedBtn" v-if="workspace.status=='waiting' && checkPermission.update">
                            Approve
                          </el-dropdown-item>
                          <el-dropdown-item command="postpaidWorkspace" v-if="checkPermission.update">
                            {{ workspace.products[0].is_postpaid ? 'Disabled Post Paid' : 'Enable Post Paid' }}
                          </el-dropdown-item>
                          <el-dropdown-item command="doEnableCallFeature" v-if="checkPermission.update && workspace.products[0] && workspace.products[0].pricing && workspace.products[0].pricing.whatsapp">
                            {{ workspace.products[0].pricing.whatsapp.enable_call_feature ? 'Disabled Call Feature' : 'Enable Call Feature' }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <el-pagination
                :page-size="20"
                layout="prev, pager, next"
                :total="dataTotal"
                :current-page="parseInt(page)"
                @current-change="changePage">
              </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModalEdit" title="Edit Workspace" hide-footer>
      <b-form @submit.prevent="saveEdit" @reset="closeModal">
        <b-form-group id="ws-name-group" label="Workspace Name:" label-for="ws-name">
          <b-form-input id="ws-name" v-model="form.name" type="text" placeholder="Enter workspace name" required />
        </b-form-group>

        <b-form-group label="Pricing">
          <b-form-group label="Authentication Price" label-for="auth_price">
            <b-form-input id="auth_price" v-model="pricing.authentication" label="Authentication Price:" type="number" placeholder="Authentication Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Marketing Price" label-for="marketing_price">
            <b-form-input id="marketing_price" v-model="pricing.marketing" label="Marketing Price:" type="number" placeholder="Marketing Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Service Price" label-for="service_price">
            <b-form-input id="service_price" v-model="pricing.service" label="Service Price:" type="number" placeholder="Service Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Utility Price" label-for="util_price">
            <b-form-input id="util_price" v-model="pricing.utility" label="Utility Price:" type="number" placeholder="Utility Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Inbound Call Price" label-for="incoming_call_price">
            <b-form-input id="incoming_call_price" v-model="pricing.incoming_call" label="Inbound Call Price:" type="number" placeholder="Inbound Call Price" step=".01" required/>
          </b-form-group>
          <b-form-group label="Outbond Call Price" label-for="outgoing_call_price">
            <b-form-input id="outgoing_call_price" v-model="pricing.outgoing_call" label="Outbond Call Price:" type="number" placeholder="Outbond Call Price" step=".01" required/>
          </b-form-group>
        </b-form-group>

        <b-form-group label="Approval" v-if="checkPermission.update && (checkUser.role.name.toLowerCase() === 'admin' || (checkUser.role_id.toLowerCase() !== '2e673799-d373-4872-a3e1-05fbb3e419e4' && checkUser.id === approved_by))">
          <b-form-group id="ws-pic" label="PIC Workspace : " label-for="ws-pic">
            <b-form-select id="ws-pic" required v-model="approval.pic_id" :options="picList" />
          </b-form-group>
          <b-form-group id="ws-plan" label="Package Plan : " label-for="ws-plan">
            <b-form-select id="ws-plan" v-model="approval.plan" :options="product" />
          </b-form-group>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal centered v-model="showModalApprove" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalApprove">
      <h5 class="text-center">
        Are you sure want to approve this workspace?
      </h5>
      <p class="mb-4 text-center">
        You can not undo this action.
      </p>
      <b-form>
        <b-form-group id="ws-pic" label="PIC Workspace : " label-for="ws-pic">
          <b-form-select id="ws-pic" v-model="approvalForm.pic_id" :options="picList" required />
        </b-form-group>
        <b-form-group
          id="document-add-file_path"
          label="Attachment : "
          label-for="document-add-file_path">
          <b-form-file
            ref="addDocumentFile"
            v-model="approvalForm.file_path"
            :accept="accepted_ext['document']"
            placeholder="Choose a file or drop it here."
            drop-placeholder="Drop file here."
          ></b-form-file>
        </b-form-group>
      </b-form>
      <div class="mt-5 mb-2 text-center">
        <el-button size="small" native-type="submit" :loading="loading.approveSubmit" @click="approveWorkspace" type="primary" class="mr-2">{{ $t('general.yes') }}</el-button>
        <el-button size="small" native-type="reset" @click="handlerHideModalApprove" type="info">{{ $t('general.no') }}</el-button>
      </div>
    </b-modal>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import { isMatch } from 'lodash';
import moment from 'moment';
import mime from 'mime';
import workspaceApi from '../../api/workspaces';
import popupErrorMessages from '../../library/popup-error-messages';
import base64File from '../../library/base64File';
import documentAPI from '../../api/companyDocuments';
import wsProductAPI from '../../api/wsProducts';
import usersAPI from '../../api/userBackoffice';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'Workspace',
  metaInfo: {
    title: 'Workspace',
  },
  data() {
    return {
      showModalEdit: false,
      showModalApprove: false,
      workspaceName: this.$route.query.search || '',
      dataSize: 0,
      dataTotal: 0,
      searchName: this.$route.query.search || '',
      page: this.$route.query.page || 1,
      workspaceList: [],
      tagColor: {
        'Basic Helpdesk': 'info',
        'Medium Package': '',
        'Large Package': 'warning',
        'Enterprice Package': 'success',
      },
      form: {
        name: '',
        ws_product_id: '',
      },
      approvalForm: {
        pic_id: '',
        file_path: null,
      },
      approval: {
        pic_id: '',
        plan: '',
      },
      pricing: {
        utility: 0,
        authentication: 0,
        marketing: 0,
        service: 0,
        incoming_call: 0,
        outgoing_call: 0,
      },
      accepted_ext: {
        document: '.pdf, .xls, .xlsx, .docx, .doc, .csv, .ppt, .pptx, .txt',
      },
      picList: [],
      product: [],
      loading: {
        approveSubmit: false,
      },
      editedId: '',
      loaderStack: 0,
      loader: null,
      workspace_id: '',
      company_id: '',
    };
  },
  async mounted() {
    this.searchWorkspace();
    this.dataTotal = this.$store.state.backOffice.companiesSize;
    this.dataSize = Math.ceil(this.$store.state.backOffice.companiesSize / 10);
  },
  computed: {
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
  },
  components: {
    Status: () => import('../components/Status.vue'),
  },
  methods: {
    copyWorkspaceId(workspace) {
      const copyText = workspace._id;
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText);
      this.$message('Copied to clipboard');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    handlerHideModalApprove() {
      this.workspace_id = '';
      this.workspace_name = '';
      this.approvalForm = {
        pic_id: '',
        file_path: null,
      };
      this.showModalApprove = false;
    },
    async approveWorkspace() {
      this.loading.approveSubmit = true;
      const fileName = this.approvalForm.file_path.name.toLowerCase();
      const fileExt = `.${mime.getExtension(this.approvalForm.file_path.type)}`;
      const type = 'document';
      if (fileName.includes('quotation') || fileName.includes('quo')) {
        const accepted = this.accepted_ext[type].split(',').map((v) => v.trim());
        if (!accepted.includes(fileExt)) {
          this.$message({
            message: this.$t('general.error.file_not_supported'),
            type: 'warning',
            duration: 10 * 1000,
          });
          return;
        }
        await workspaceApi.approval(this.workspace_id, this.company_id, { pic_id: this.approvalForm.pic_id })
          .then(async (response) => {
            await popupErrorMessages(response);
            await this.createDocument();
            // this.$message({
            //   message: this.$t('workspace.success.approve'),
            //   type: 'success',
            // });
            await this.$store.dispatch('backOffice/loadWorkspaces', this.company_id);
            this.handlerHideModalApprove();
          });
      } else {
        this.$message({
          message: this.$t('general.error.file_not_supported'),
          type: 'warning',
          duration: 10 * 1000,
        });
        return;
      }
      this.loading.approveSubmit = false;
    },
    async createDocument() {
      const documentForm = {
        file_name: `Quotation ${this.workspace_name}`,
        category: 'Quotation',
        company_id: this.company_id,
        workspace_ids: [this.workspace_id],
      };
      documentForm.file = await base64File(this.approvalForm.file_path);
      const response = await documentAPI.createDocument(
        documentForm,
      ).catch(() => {});
      await popupErrorMessages(response);
      this.$message({
        message: this.$t('workspace.success.approve'),
        type: 'success',
      });
    },
    postpaidWorkspace(workspace) {
      this.$confirm(this.$t('workspace.confirm.postpaid'), 'Confirmation', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await workspaceApi.pricing_plan(workspace._id, workspace.company_id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('workspace.success.postpaid'),
                  type: 'success',
                });
                this.searchWorkspace();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    clearForm() {
      this.form.name = '';
      this.form.ws_product_id = '';
    },
    closeModal() {
      this.showModal = false;
      this.showModalEdit = false;
      this.clearForm();
    },
    async loadProduct() {
      await wsProductAPI.getList()
        .then((res) => {
          this.product = res.data.rows.map((item) => ({
            text: item.name,
            value: item._id,
          }));
        });
    },
    async loadPIC() {
      await usersAPI.getPIC()
        .then((res) => {
          this.picList = res.data.rows.map((item) => ({
            text: item.full_name,
            value: item.id,
          }));
        });
    },
    editWorkspace(workspace) {
      this.showLoader();
      this.editedId = workspace._id;
      this.form.name = workspace.name;
      this.approval.pic_id = workspace.pic_id;
      this.approval.plan = workspace.products[0]._id;
      this.approved_by = workspace.approved_by;
      if (workspace.products[0].is_postpaid !== undefined) {
        this.selected = workspace.products[0].is_postpaid === true ? 'postpaid' : 'prepaid';
      } else {
        this.selected = '';
      }
      if (workspace.products[0].pricing && workspace.products[0].pricing.whatsapp) {
        const priceList = workspace.products[0].pricing.whatsapp;
        this.pricing.authentication = priceList.authentication_conversation;
        this.pricing.utility = priceList.utility_conversation;
        this.pricing.marketing = priceList.marketing_conversation;
        this.pricing.service = priceList.service_conversation;
        this.pricing.incoming_call = priceList.incoming_call;
        this.pricing.outgoing_call = priceList.outgoing_call;
      } else {
        this.pricing.authentication = 0;
        this.pricing.utility = 0;
        this.pricing.marketing = 0;
        this.pricing.service = 0;
        this.pricing.incoming_call = 0;
        this.pricing.outgoing_call = 0;
      }
      this.loadPIC();
      this.loadProduct();
      this.showModalEdit = true;
      this.hideLoader();
    },
    async saveEdit() {
      this.showLoader();
      await workspaceApi.update(this.editedId, {
        name: this.form.name,
        pricing: {
          utility: this.pricing.utility,
          authentication: this.pricing.authentication,
          marketing: this.pricing.marketing,
          service: this.pricing.service,
          incoming_call: this.pricing.incoming_call,
          outgoing_call: this.pricing.outgoing_call,
        },
        approval: this.approval,
      }).then(async () => {
        this.$message({
          message: this.$t('workspace.success.edit'),
          type: 'success',
        });
        this.searchWorkspace();
        this.closeModal();
      });
      this.hideLoader();
    },
    dateConverter(date) {
      const createdAt = new Date(date);
      return `${createdAt.toLocaleDateString('id-ID')} ${createdAt.toLocaleTimeString('id-ID')}`;
    },
    async setWorkspace(workspace) {
      await this.$store.dispatch('company/setActiveCompany', { id: workspace.company_id });
      await this.$store.dispatch('backOffice/setActiveCompany', workspace.company_id);
      this.$store.dispatch('workspace/setActiveWorkspace', workspace)
        .then(() => {
          this.$router.push('/dashboard');
        });
    },
    async searchWorkspace() {
      this.searchName = this.workspaceName;
      const options = { page: this.page, search: this.searchName };

      if (!isMatch(this.$route.query, options)) {
        this.$router.replace({ query: options });
      }
      console.log('Reload...');
      const loader = this.$loading.show();
      const response = await workspaceApi.getAllWorkspaces(options);
      if (!response.error) {
        this.workspaceList = response.data.rows;
        this.dataTotal = response.data.total;
      }
      loader.hide();
    },
    async changePage(currentPage) {
      this.page = currentPage;
      this.searchWorkspace();
    },
    doEnableCallFeature(workspace) {
      this.$confirm(this.$t('workspace.confirm.call_feature'), 'Confirmation', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const response = await workspaceApi.updateCallFeature({
              id: workspace._id,
              company_id: workspace.company_id,
              data: {
                enable_call_feature: !workspace.products[0].pricing.whatsapp.enable_call_feature,
              },
            });
            instance.confirmButtonLoading = false;
            try {
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('workspace.success.edit'),
                type: 'success',
              });
            } catch (error) {
              console.log(error);
            }
            this.searchWorkspace();
          }
          cb();
        },
      }).catch(() => {});
    },
    commandHandler($event, workspace) {
      if (this[$event]) this[$event](workspace);
    },
    handlerApprovedBtn(workspace) {
      this.workspace_id = workspace._id;
      this.company_id = workspace.company_id;
      this.workspace_name = workspace.name;
      this.approvalForm.pic_id = workspace.pic_id;
      this.showModalApprove = true;
      this.loadPIC();
    },
  },
};
</script>
